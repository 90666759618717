/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { Descriptions, Badge, Empty, Spin } from 'antd';

const Readings = props => {
  const { response, loading } = props;

  const IsLoading = (
    <Spin tip="Loading...">
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description="Submit form to view results"
      />
    </Spin>
  );

  return (
    <>
      {response && response.billPeriod && !loading ? (
        <Descriptions title={response.name || response.user.name} bordered>
          <Descriptions.Item label="Units">{response.units}</Descriptions.Item>
          <Descriptions.Item label="Meter Reading">
            {response.currentReading}
          </Descriptions.Item>
          <Descriptions.Item label="Amount to Pay">
            Ksh. {response.amountToPay}
          </Descriptions.Item>

          <Descriptions.Item label="Status" span={3}>
            {new Date().getMonth() - new Date(response.updatedAt).getMonth() <
            4 ? (
              <Badge color="green" status="processing" text="Active" />
            ) : (
              <Badge color="red" text="Inactive" />
            )}
          </Descriptions.Item>
        </Descriptions>
      ) : loading ? (
        IsLoading
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description="Submit form to view results"
        />
      )}
    </>
  );
};

export default Readings;
