/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import Cookie from 'js-cookie';
import Profile from '../views/AdminProfile';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

import { style } from 'variables/Variables.jsx';

import routes from 'routes.js';

const profile = {
  path: '/profile/:id',
  name: 'Profile',
  icon: 'pe-7s-user',
  component: Profile,
  layout: '/admin',
};

const Admin = (props) => {
  const userInfo = JSON.parse(Cookie.get('userInfo'));
  const accessRoutes = userInfo.super
    ? routes
    : routes.filter((route) => route.name === 'Meter Reading');
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  const windowUpdate = () => {
    if (
      window.innerWidth < 993 &&
      props.history.location.pathname !== props.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (props.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  };
  useEffect(() => {
    windowUpdate();
  }, []);

  return (
    <div className="wrapper">
      <NotificationSystem style={style} />
      <Sidebar {...props} routes={accessRoutes} />
      <div id="main-panel" className="main-panel">
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>{getRoutes([...accessRoutes, profile])}</Switch>
        <Footer />
      </div>
    </div>
  );
};

export default Admin;
