import React from 'react';
import { NavItem, Nav } from 'react-bootstrap';
import Cookie from 'js-cookie';

const AdminNavbarLinks = () => {
  const handleLogout = () => {
    Cookie.remove('userInfo');
    return window.location.assign('/login');
  };

  // const notification = (
  //   <div>
  //     <i className="fa fa-globe" />
  //     <span className="notification">5</span>
  //     <p className="hidden-lg hidden-md">Notification</p>
  //   </div>
  // );

  const userInfo = JSON.parse(Cookie.get('userInfo'));

  return (
    <div>
      <Nav>
        <NavItem eventKey={1} href="#">
          <i className="fa fa-dashboard" />
          <p className="hidden-lg hidden-md">Dashboard</p>
        </NavItem>
        {/* <NavItem eventKey={2} href="/admin/notifications">
          {notification}
        </NavItem> */}
      </Nav>
      <Nav pullRight>
        <NavItem eventKey={3} href={`/admin/profile/${userInfo._id}`}>
          Account
        </NavItem>
        {/* <NavDropdown
            eventKey={2}
            title="Dropdown"
            id="basic-nav-dropdown-right"
          >
            <MenuItem eventKey={2.1}>Action</MenuItem>
            <MenuItem eventKey={2.2}>Another action</MenuItem>
            <MenuItem eventKey={2.3}>Something</MenuItem>
            <MenuItem eventKey={2.4}>Another action</MenuItem>
            <MenuItem eventKey={2.5}>Something</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={2.5}>Separated link</MenuItem>
          </NavDropdown> */}
        <NavItem eventKey={3} href="#" onClick={handleLogout}>
          Log out
        </NavItem>
      </Nav>
    </div>
  );
};

export default AdminNavbarLinks;
