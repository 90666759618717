/* eslint-disable react/display-name */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Cookie from 'js-cookie';
import Card from 'components/Card/Card.jsx';
import Modals from 'components/Modal/Modal.jsx';
import { CSVLink, CSVDownload } from 'react-csv';

import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  message,
  Space,
  Modal,
  Descriptions,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const childNode = children;
  return <td {...restProps}>{childNode}</td>;
};

export default function EditableTable() {
  const [dataSource, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [response, setResponse] = useState(null);
  const [inputs, setInputs] = useState({});
  const [searchText, setSearchText] = useState('');
  const [bills, setBills] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [allDatas, setAllDatas] = useState([]);
  const [payments, setPayments] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, setUser] = useState(null);

  let searchInput = useRef(null);
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: isvisible => {
      if (isvisible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    getData();
    getBills();
    getPayments();
  }, [response]);

  const { token } = JSON.parse(Cookie.get('userInfo'));

  const getBills = async () => {
    const { data } = await axios.get('/api/bills', {
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    });
    setBills(data);
  };

  async function getData() {
    const { data } = await axios.get('/api/users/list');
    if (data) {
      setData(data);
    }
  }

  const getPayments = async () => {
    const { data } = await axios.get('/api/bills/payment', {
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    });
    setPayments(data);
  };

  const showModal = usr => {
    setUser(usr);
    console.log(usr);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setUser(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUser(null);
  };

  useEffect(() => {
    if (dataSource && bills && payments) {
      const userBills = {};
      const userPayments = {};

      bills.forEach(item => {
        if (userBills[item?.user?._id]) {
          userBills[item?.user?._id].push(item);
        } else {
          userBills[item?.user?._id] = [item];
        }
      });

      payments.forEach(item => {
        if (
          `${eval(new Date(item.createdAt).getMonth() + 1)}/${new Date(
            item.createdAt
          ).getFullYear()}` ===
          `${eval(new Date().getMonth())}/${new Date().getFullYear()}`
        ) {
          if (userPayments[item.user._id]) {
            userPayments[item.user._id].push(item);
          } else {
            userPayments[item.user._id] = [item];
          }
        }
      });

      const userDatas = [];
      dataSource.forEach(item => {
        let userObj = {};
        if (userBills[item._id]) {
          const billId = userBills[item._id];
          userObj = {
            ...billId[0],
            previousReading: billId[1] ? billId[1].currentReading : 0,
            previousBalance: billId[1] ? billId[1].amountToPay : 0,
            currentBill: billId[1]
              ? billId[0].amountToPay - billId[1].amountToPay
              : billId[0].amountToPay,
            amountPaid: userPayments[item._id]
              ? userPayments[item._id].reduce((acc, val) => acc + val.amount, 0)
              : 0,
            ...item,
          };
        } else {
          userObj = {
            ...item,
            previousReading: 0,
            previousBalance: 0,
            currentBill: 0,
            units: 0,
            currentReading: 0,
            amountToPay: 0,
            amountPaid: userPayments[item._id]
              ? userPayments[item._id].reduce((acc, val) => acc + val.amount, 0)
              : 0,
          };
        }
        userDatas.push(userObj);
      });
      setAllDatas([...userDatas]);
    }
  }, [dataSource, bills, payments]);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Meter Number', key: 'meterNumber' },
    { label: 'Phone Number', key: 'phone' },
    { label: 'Location', key: 'location' },
    { label: 'Units', key: 'units' },
    { label: 'Previus Reading', key: 'previousReading' },
    { label: 'Current Reading', key: 'currentReading' },
    { label: 'Balance', key: 'amountToPay' },
    { label: 'Amount To Pay', key: 'amount' },
  ];

  const titles = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <a
          onClick={() => {
            // eslint-disable-next-line no-console
            setInputs({
              ...record,
              fname: record.name.split(' ')[0],
              lname: record.name.split(' ')[1],
            });
            setVisible(true);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ...getColumnSearchProps('location'),
    },
    {
      title: 'Meter Number',
      dataIndex: 'meterNumber',
      key: 'meterNumber',
      ...getColumnSearchProps('meterNumber'),
    },

    {
      title: 'Current Reading',
      dataIndex: 'currentReading',
      key: 'currentReading',
      ...getColumnSearchProps('currentReading'),
    },
    {
      title: 'Previous Reading',
      dataIndex: 'previousReading',
      key: 'previousReading',
      ...getColumnSearchProps('previousReading'),
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      ...getColumnSearchProps('units'),
    },

    {
      title: 'Amount to Pay',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Balance',
      dataIndex: 'amountToPay',
      key: 'amountToPay',
    },
  ];

  const tcolumns = titles.map(item => ({
    ...item,
  }));

  tcolumns.push({
    title: 'operation',
    dataIndex: 'operation',
    render: (text, record) =>
      dataSource.length >= 1 ? (
        <div className='operation_icons'>
          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => handleDelete(record._id)}
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
          <a onClick={() => showModal(record)}>
            <EyeOutlined />
          </a>
        </div>
      ) : null,
  });

  const handleDelete = async id => {
    const userInfo = JSON.parse(Cookie.get('userInfo'));
    try {
      const { data } = await axios.delete(`/api/users/${id}`, {
        headers: { Authorization: ` Bearer ${userInfo.token}` },
      });
      setResponse(data);
      message.success('User deleted successfully!');
    } catch (error) {
      message.error('Error performing the operation!');
    }
  };

  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = tcolumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const strDate = () => {
    let options = { month: 'long', day: 'numeric', year: 'numeric' };

    let date = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    var d = new Date(year, month, date);
    d.setMonth(d.getMonth());
    return d.toLocaleDateString('en-US', options);
  };

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={12} sm={8}>
            <Card
              title='QuontFarm Water Clients'
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <Modal
                    title={user?.name}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700}
                    cancelText='Close'
                  >
                    <Descriptions layout='vertical' bordered>
                      <Descriptions.Item label='Meter number'>
                        {user?.meterNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label='Previous reading'>
                        {user?.previousReading}
                      </Descriptions.Item>
                      <Descriptions.Item label='Current reading'>
                        {user?.currentReading}
                      </Descriptions.Item>
                      <Descriptions.Item label='Units'>
                        {user?.units}
                      </Descriptions.Item>
                      <Descriptions.Item label='Cost per unit' span={2}>
                        Ksh. 130.00
                      </Descriptions.Item>
                      <Descriptions.Item label='Standing charge'>
                        Ksh. 200
                      </Descriptions.Item>
                      <Descriptions.Item label='previous balance'>
                        Ksh. {user?.previousBalance}
                      </Descriptions.Item>
                      <Descriptions.Item label='Amount to be paid'>
                        Ksh. {user?.amountToPay}
                      </Descriptions.Item>
                    </Descriptions>
                  </Modal>
                  <div className='top-btns'>
                    <Button
                      className='table-space'
                      type='primary'
                      style={{
                        marginBottom: 16,
                      }}
                      onClick={() => setVisible(true)}
                    >
                      Add Client
                    </Button>
                    {allDatas && (
                      <CSVLink
                        data={allDatas.map((item, i) => ({
                          ...item,
                          phone: item.phone.join('/'),
                          key: i,
                        }))}
                        headers={headers}
                        className='btn btn-primary'
                        filename={`Quontfarm bills ${strDate()}.csv`}
                      >
                        Download data
                      </CSVLink>
                    )}
                  </div>
                  <Modals
                    visible={visible}
                    setVisible={setVisible}
                    title='New User'
                    setResponse={setResponse}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={
                      allDatas &&
                      allDatas.map((item, i) => ({
                        ...item,
                        phone: item.phone.join('/'),
                        key: i,
                      }))
                    }
                    columns={columns}
                    loading={!allDatas || !bills}
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
