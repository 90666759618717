import { Drawer } from 'antd';
import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';

import AdminNavbarLinks from './AdminNavbarLinks.jsx';

const Header = (props) => {
  const [sidebarExists, setSidebarExists] = useState(false);

  function mobileSidebarToggle(e) {
    if (sidebarExists === false) {
      setSidebarExists(true);
    }

    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  }

  return (
    <Navbar fluid>
      <Navbar.Header>
        <Drawer/>
        <Navbar.Brand>
          <p>{props.brandText}</p>
        </Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
      </Navbar.Header>
      <Navbar.Collapse>
        <AdminNavbarLinks />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
