import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Cookie from 'js-cookie';

import AdminNavbarLinks from '../Navbars/AdminNavbarLinks.jsx';
import logo from 'assets/img/logo.png';

const Sidebar = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const image =
    'https://res.cloudinary.com/dnzr6wrju/image/upload/v1606293116/quontfarm/Optimized-samara-doole-peaTniZsUQs-unsplash_ifzrpb.jpg';

  function updateDimensions() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions());
  }, []);

  const sidebarBackground = {
    backgroundImage: `url(${image})`,
  };

  const userInfo = JSON.parse(Cookie.get('userInfo'));

  const routes = userInfo.super
    ? props.routes
    : props.routes.filter((route) => route.name === 'Meter Reading');

  return (
    <div id="sidebar" className="sidebar" data-color="black" data-image={image}>
      <div className="sidebar-background" style={sidebarBackground} />

      <div className="logo">
        <Link to="/admin/dashboard" className="simple-text logo-normal">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {width <= 991 ? <AdminNavbarLinks /> : null}
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
