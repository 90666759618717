/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import ChartistGraph from 'react-chartist';
import { Grid, Row, Col } from 'react-bootstrap';
import { Spin, Progress, Button } from 'antd';
import axios from 'axios';
import Cookie from 'js-cookie';

import { Card } from 'components/Card/Card.jsx';
import { StatsCard } from 'components/StatsCard/StatsCard.jsx';

import datas from '../data.json';

const Dashboard = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState(0);
  const [bills, setBills] = useState(null);
  const [payments, setPayments] = useState(null);
  const [series, setSeries] = useState([0, 0, 0]);

  const { token } = JSON.parse(Cookie.get('userInfo'));

  useEffect(() => {
    getUsers();
    getBills();
    getPayments();
  }, []);

  useEffect(() => {
    if (users && bills) {
      setPercent(getPercent(bills));
    }
    if (bills) {
      const thisSeries = [
        bills &&
          bills.filter(item => {
            if (prevMonths(item)) {
              return item.units >= 50;
            }
          }).length,
        bills &&
          bills.filter(item => {
            if (prevMonths(item)) {
              return item.units < 50 && item.units > 20;
            }
          }).length,
        bills &&
          bills.filter(item => {
            if (prevMonths(item)) {
              return item.units < 20;
            }
          }).length,
      ];
      if (thisSeries.every(item => item == 0)) {
        thisSeries[2] = users.length;
      }
      setSeries(thisSeries);
    }
  }, [users, bills]);

  const getUsers = async () => {
    const { data } = await axios.get('/api/users/list');
    setLoading(false);
    setUsers(data);
  };

  const getBills = async () => {
    const { data } = await axios.get('/api/bills', {
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    });

    setBills(data);
  };

  const getPayments = async () => {
    const { data } = await axios.get('/api/bills/payment', {
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    });
    setPayments(data);
  };

  const getPercent = data => {
    const settled = data.filter(
      item =>
        (item.amountToPay === 0 &&
          `${new Date(item.billPeriod).getMonth()}/${new Date(
            item.billPeriod
          ).getFullYear()}` ===
            `${new Date().getMonth()}/${new Date().getFullYear()}`) ||
        (`${new Date(item.billPeriod).getMonth()}/${new Date(
          item.billPeriod
        ).getFullYear()}` ===
          `${new Date().getMonth() - 1}/${new Date().getFullYear()}` &&
          item.amountToPay === 0)
    );

    const per = (settled.length / users.length) * 100;

    return Math.round(per);
  };

  const createLegend = json => {
    const legend = [];
    for (let i = 0; i < json.names.length; i++) {
      const type = `fa fa-circle text-${json.types[i]}`;
      legend.push(<i className={type} key={i} />);
      legend.push(` ${json.names[i]}`);
    }
    return legend;
  };

  const revenue = items => {
    let prices = items.map(item => item.amount);
    return prices.length > 0 ? prices.reduce((acc, val) => acc + val) : 0;
  };

  const debts = items => {
    const moneys = items.map(item => item.amountToPay);
    return moneys.length > 0 ? moneys.reduce((acc, val) => acc + val) : 0;
  };

  const unreadMeters = items => {
    const readings = items.filter(
      item =>
        `${new Date(item.billPeriod).getMonth()}/${new Date(
          item.billPeriod
        ).getFullYear()}` ===
        `${new Date().getMonth()}/${new Date().getFullYear()}`
    );
    return users.length - readings.length;
  };

  const prevMonths = item => {
    return (
      `${new Date(item.billPeriod).getMonth()}/${new Date(
        item.billPeriod
      ).getFullYear()}` ===
        `${new Date().getMonth()}/${new Date().getFullYear()}` ||
      `${new Date(item.billPeriod).getMonth()}/${new Date(
        item.billPeriod
      ).getFullYear()}` ===
        `${new Date().getMonth() - 1}/${new Date().getFullYear()}`
    );
  };

  const dataPie = {
    labels: series || [0, 0, 0],
    series: series || [0, 0, 0],
  };
  const legendPie = {
    names: ['20 - 50 units', 'Less than 20 units', '50+ units'],
    types: ['danger', 'warning', 'info'],
  };

  return (
    <div className="content">
      {loading ? (
        <div className="loader-align">
          <Spin size="large" />
        </div>
      ) : (
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Clients"
                statsValue={users && users.length.toLocaleString('en-US')}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Revenue (KSHS)"
                statsValue={
                  payments && `${revenue(payments).toLocaleString('en-US')}`
                }
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Total Debts (KSHS)"
                statsValue={bills && debts(bills).toLocaleString('en-US')}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-calculator text-info" />}
                statsText="Unread Meters"
                statsValue={
                  bills && unreadMeters(bills).toLocaleString('en-US')
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Usage Analyis"
                category="Previous Month"
                stats="Updated now"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    {series.length === 0 ? (
                      <p>No data.</p>
                    ) : (
                      <ChartistGraph data={dataPie} type="Pie" />
                    )}
                  </div>
                }
                legend={<div className="legend">{createLegend(legendPie)}</div>}
              />
            </Col>
            <Col lg={8} md={8} sm={12}>
              <Card
                title="Fully Settled Bills"
                content={
                  <div className="centered-flex">
                    <Progress
                      type="circle"
                      percent={percent || 0}
                      responsive="true"
                      width={375}
                    />
                  </div>
                }
              />
            </Col>
          </Row>

          <Row>
            {/* <Col md={6}>
              <Card
                id="chartActivity"
                title="2014 Sales"
                category="All products including Taxes"
                stats="Data information certified"
                statsIcon="fa fa-check"
                content={(
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
)}
                legend={<div className="legend">{createLegend(legendBar)}</div>}
              />
            </Col>

            <Col md={6}>
              <Card
                title="Tasks"
                category="Backend development"
                stats="Updated 3 minutes ago"
                statsIcon="fa fa-history"
                content={(
                  <div className="table-full-width">
                    <table className="table">
                      <Tasks />
                    </table>
                  </div>
)}
              />
            </Col> */}
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
