/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Cookie from 'js-cookie';
import { Link } from 'react-router-dom';

// reactstrap components
import { Form, Input, Button, Checkbox, message } from 'antd';
import { PhoneOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [response, setResponse] = useState(null);
  const [phoneNo, setPhone] = useState({});

  const styles = {
    display: showPassword ? 'block' : 'none',
  };

  const handleSubmit = async (e) => {
    try {
      if (response && response.password) {
        const { data } = await axios.post('/api/users/signin', e);
        if (data) {
          Cookie.set('userInfo', JSON.stringify(data));
          return data.super ? window.location.assign('/admin/dashboard') : window.location.assign('/admin/reading');
        }
      } else {
        const { data } = await axios.put(`/api/users/${response._id}`, {
          password: e.password,
        });
        if (data) {
          Cookie.set('userInfo', JSON.stringify(data));
          return data.super ? window.location.assign('/admin/dashboard') : window.location.assign('/admin/reading');
        }
      }
    } catch (error) {
      console.log(error);
      message.error('Please check your phone number or password!');
    }
  };

  const checkRegistered = async (phone) => {
    try {
      const { data } = await axios.get(`/api/users/check/${phone}`);
      if (data) {
        setResponse(data);
        if (data.password) {
          setShowPassword(true);
        }
      }
    } catch (error) {
      message.error('The phone number is not registered!');
    }
  };

  return (
    <div className="login-page">
      <div className="split left">
        <h2>QUONTFARM PRODUCTS</h2>
      </div>

      <div className="split right">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            rules={[
              {
                required: true,
                message: 'Please input your Phone number!',
              },
            ]}
          >
            <Input
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder="Phone number"
              size="large"
            />
          </Form.Item>
          <Button
            onClick={() => checkRegistered(phoneNo)}
            type="primary"
            style={{ display: response ? 'none' : 'block' }}
          >
            Submit
          </Button>
          <div
            style={{
              display:
                !response || (response && response.password) ? 'none' : 'block',
            }}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: response && !response.password,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: response && !response.password,
                  message: 'Please confirm your password!',
                },
                response && !response.password
                  ? ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'The two passwords that you entered do not match!'
                        );
                      },
                    })
                  : '',
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </div>
          <Form.Item
            name="password"
            style={styles}
            rules={[
              {
                required: response && response.password,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link className="login-form-forgot" to="/passwordreset">
              Forgot password
            </Link>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="large"
              style={{ display: response ? 'block' : 'none' }}
            >
              {response && response.password ? 'Log in' : 'Submit'}
            </Button>
           
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
