/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import Dashboard from 'views/Dashboard.jsx';
import UserProfile from 'views/UserProfile.jsx';
import TableList from 'views/TableList.jsx';
import Messages from 'views/Messages.jsx';
import Meter from 'views/Meter';
import Payment from 'views/Payment';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'pe-7s-graph',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/readers',
    name: 'Staff',
    icon: 'pe-7s-user',
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/table',
    name: 'Users List',
    icon: 'pe-7s-note2',
    component: TableList,
    layout: '/admin',
  },
  {
    path: '/reading',
    name: 'Meter Reading',
    icon: 'pe-7s-display1',
    component: Meter,
    layout: '/admin',
  },
  {
    path: '/payment',
    name: 'Update Payment',
    icon: 'pe-7s-cash',
    component: Payment,
    layout: '/admin',
  },
    {
    path: '/messages',
    name: 'Messages',
    icon: 'pe-7s-mail',
    component: Messages,
    layout: '/admin',
  },
];

export default dashboardRoutes;
