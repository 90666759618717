import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Cookie from 'js-cookie';

import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import axios from 'axios';
import { message, Form, Input } from 'antd';

const PasswordReset = () => {
  const [inputs, setInputs] = useState(null);
  const [response, setResponse] = useState(null);
  const [coded, setCoded] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.put(`/api/users/${response.user}`, inputs);
    if (data) {
      message.success('Password updated successfully!');

      Cookie.set('userInfo', JSON.stringify(data));
      setResponse(data);
      data.super
        ? window.location.assign('/admin/dashboard')
        : window.location.assign('/admin/reader');
    }
  };

  const checkPhone = async () => {
    try {
      if (inputs && inputs.phone) {
        const { data } = await axios.get(`/api/users/reset/${inputs.phone}`);
        data ? setResponse(data) : message.error('Please check phone number!');
      } else {
        message.error('Please enter your phone number!');
      }
    } catch (error) {
      message.error('Please check phone number!');
    }
  };

  const confirmCode = async () => {
    try {
      const { data } = await axios.get(`/api/users/code/${inputs.code}`);
      if (data) {
        setCoded(data);
      }
    } catch (error) {
      message.error('The code does not match!');
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <div className="centered">
      <Grid fluid>
        <Row>
          <Col>
            <Card
              title="Reset Password"
              content={
                <form onSubmit={onSubmit}>
                  <FormInputs
                    ncols={['col-md-12']}
                    
                    properties={[
                      {
                        style:{ display: response ?  'none' : 'block'},
                        label: !response ? 'Phone' : null,
                        type: 'tel',
                        bsClass: 'form-control',
                        autocomplete: 'off',
                        placeholder: 'Phone Number',
                        name: 'phone',
                        required: true,
                        onChange: handleChange,
                      },
                    ]}
                  />
                  <Button
                    onClick={checkPhone}
                    bsStyle="info"
                    pullRight
                    fill
                    type="button"
                    style={{ display: response ? 'none' : 'block' }}
                  >
                    Submit
                  </Button>

                  <FormInputs
                    ncols={['col-md-12']}
                    
                    properties={[
                      {
                        style:{ display: !response || coded ? 'none' : 'block'},
                        label: response && !coded ? 'Confirmation Code' : null,
                        type: 'number',
                        bsClass: 'form-control',
                        placeholder: 'Confirmation Code',
                        name: 'code',
                        autocomplete: 'off',
                        required: true,
                        onChange: handleChange,
                      },
                    ]}
                  />
                  <Button
                    onClick={confirmCode}
                    bsStyle="info"
                    pullRight
                    fill
                    type="button"
                    style={{ display: response && !coded ? 'block' : 'none' }}
                  >
                    Confirm
                  </Button>

                  <div
                    style={{
                      display: !coded ? 'none' : 'block',
                    }}
                  >
                    <Form.Item
                      name="password"
                     
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="Password" size="large"  onChange= {(e)=>setInputs({...inputs, password: e.target.value})} />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              'The two passwords that you entered do not match!'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        size="large"
                      />
                    </Form.Item>
                  </div>
                  <Button
                    style={{
                      display: coded ? 'block' : 'none',
                    }}
                    bsStyle="info"
                    pullRight
                    fill
                    type="submit"
                  >
                    Update Password
                  </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default PasswordReset;
