import React from 'react';
import ReactDOM from 'react-dom';
import Cookie from 'js-cookie';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';

import AdminLayout from 'layouts/Admin.jsx';
import Login from 'views/Login.jsx';
import passwordReset from 'views/PasswordReset';

const CheckAuth = () => {
  const userInfo = Cookie.get('userInfo');
  return userInfo && userInfo.super ? (
    <Redirect from={'/' || '/login'} to="/admin/dashboard" />
  ) : userInfo && !userInfo.super ? (
    <Redirect from={'/' || '/login'} to="/admin/reading" />
  ) : (
    <Redirect from="/" to="/login" />
  );
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {Cookie.get('userInfo') ? (
        <Route path="/admin" render={(prop) => <AdminLayout {...prop} />} />
      ) : (
        <Redirect from="/admin" to="/login" />
      )}
      {Cookie.get('userInfo') && (
        <Redirect from="/login" to="/admin/dashboard" />
      )}
      <Route exact path="/login" component={Login} />
      <Route exact path="/passwordreset" component={passwordReset} />
      <Route path="/" exact render={CheckAuth} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
