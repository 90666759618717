/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, message } from 'antd';
import Cookie from 'js-cookie';
import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import axios from 'axios';

const SHowModal = (props) => {
  const { visible, setVisible, title, setResponse, inputs, setInputs } = props;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(Cookie.get('userInfo'));
    const form = document.getElementById('reg-form');
    const { fname, lname, phone, meterNumber, location } = inputs;
    if (!fname || !lname || !phone || !meterNumber || !location) {
      message.error('Please fill all fields!');
      return;
    }
    console.log(inputs);
    setVisible(false);
    try {
      let data;
      if (!inputs._id) {
        data = await axios.post('/api/users/register', inputs, {
          headers: {
            Authorization: ` Bearer ${userInfo.token}`,
          },
        });
      } else if (inputs._id) {
        data = await axios.put(`/api/users/${inputs._id}`, inputs, {
          headers: { Authorization: ` Bearer ${userInfo.token}` },
        });
      }
      if (data) {
        setResponse(data.data);
        message.success('Client saved successfully!');
        form.reset();
        setInputs({});
      } else {
        message.error('Error occurred while saving data!');
      }
    } catch (error) {
      message.error('Meter number is already registered!');
    }
  };

  return (
    <>
      <Modal
        title={title}
        centered
        visible={visible}
        onOk={handleSubmit}
        onCancel={() => { setVisible(false) }}
        width={1000}
        okText="Add User"
      >
        <Card
          title=""
          content={
            <form id="reg-form">
              <FormInputs
                ncols={['col-md-6', 'col-md-6']}
                properties={[
                  {
                    label: 'First name',
                    type: 'text',
                    bsClass: 'form-control',
                    name: 'fname',
                    required: true,
                    placeholder: 'First name',
                    onChange: handleChange,
                    value: inputs.fname || '',
                  },
                  {
                    label: 'Last name',
                    type: 'text',
                    required: true,
                    bsClass: 'form-control',
                    placeholder: 'Last name',
                    name: 'lname',
                    value: inputs.lname || '',
                    onChange: handleChange,
                  },
                ]}
              />
              <FormInputs
                ncols={['col-md-6', 'col-md-6']}
                properties={[
                  {
                    label: 'Location',
                    type: 'text',
                    bsClass: 'form-control',
                    placeholder: 'Location',
                    required: true,
                    name: 'location',
                    onChange: handleChange,
                    value: inputs.location || '',
                    // defaultValue:
                    //   "Chui Road"
                  },
                  {
                    label: 'Phone Number',
                    type: 'number',
                    bsClass: 'form-control',
                    placeholder: 'Phone number',
                    value: inputs.phone || '',
                    name: 'phone',
                    required: true,
                    onChange: handleChange,
                    // defaultValue: "0700 123 456"
                  },
                ]}
              />

              <FormInputs
                ncols={['col-md-3']}
                properties={[
                  {
                    label: 'Meter Number',
                    type: 'number',
                    required: true,
                    bsClass: 'form-control',
                    placeholder: 'Meter number',
                    name: 'meterNumber',
                    onChange: handleChange,
                    value: inputs.meterNumber || '',
                  },
                ]}
              />

              <div className="clearfix" />
            </form>
          }
        />
      </Modal>
    </>
  );
};

export default SHowModal;
