/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Grid, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { message, Button, Modal, Space, Table, Popconfirm } from 'antd';
import Card from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Readings from 'components/Readings/Readings';

const styles = {
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  fontSize: '24px',
};

export default function Payment() {
  const [inputs, setInputs] = useState({});
  const [response, setResponse] = useState(null);
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const handleChange = e => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const getUser = async e => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`/api/users/user/${inputs.meterNumber}`);
      setUser(data);
    } catch (error) {
      message.error('Please check meter number!');
    }
  };

  const getPayments = async () => {
    try {
      const { data } = await axios.get(`/api/bills/payments/${user._id}`);
      setPayments(data);
    } catch (error) {
      message.error('Error getting data!');
    }
  };

  useEffect(() => {
    if (user) {
      getPayments();
    }
  }, [user]);

  const handleSubmit = async e => {
    e.preventDefault();
    const { meterNumber, amount } = inputs;
    if (!meterNumber || !amount) {
      message.error('Please fill all fields!');
      return;
    }
    setLoading(true);
    try {
      if (user) {
        const { data } = await axios.post(`/api/bills/payment`, {
          ...inputs,
          user: user._id,
          method: 'Cash',
        });

        setResponse(data);
        getPayments();
        setLoading(false);
        message.success('Client payment updated!');
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
      message.error('An error occured!');
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    setLoading(true);
    const form = document.getElementById('payment-form');
    const { data } = await axios.put(`/api/bills/edit/payment/${inputs._id}`, {
      ...inputs,
      amount: Number(inputs.amount),
    });

    setLoading(false);
    getPayments();
    message.success('Client payment updated!');
    setVisible(false);

    setInputs({});
  };

  const confirm = async item => {
    try {
      const { data } = await axios.delete(
        `/api/bills/payment/${item?._id}`,
        item
      );
      if (data) {
        message.success('Payment deleted!');
        getPayments();
      }
    } catch (error) {
      message.error('An error occurred while deleteing data');
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',

      // eslint-disable-next-line react/display-name
      render: (date, record) => <p>{new Date(date).toLocaleDateString()}</p>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, i) => {
        if (i === 0) {
          return (
            <Space size='middle'>
              <a
                onClick={() => {
                  setFormValues(record);
                  setInputs(record);
                  setVisible(true);
                }}
              >
                Edit
              </a>
              {record?.method === 'Cash' && (
                <Popconfirm
                  title='Are you sure you want to delete payment?'
                  onConfirm={() => confirm(record)}
                >
                  <a>Delete</a>
                </Popconfirm>
              )}
            </Space>
          );
        }
      },
    },
  ];

  return (
    <div>
      <div className='payment-banner' />
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <div style={styles}>
                  <h5>{user ? user.name : 'Enter meter number'}</h5>
                </div>
              }
              ctTableResponsive
              content={
                <>
                  {!user && (
                    <Form id='meter-form' onSubmit={getUser}>
                      <FormInputs
                        ncols={['col-md-12']}
                        properties={[
                          {
                            label: 'Meter Number',
                            type: 'number',
                            required: true,
                            bsClass: 'form-control',
                            placeholder: 'Meter number',
                            name: 'meterNumber',
                            onChange: handleChange,
                          },
                        ]}
                      />
                      <Button type='primary' htmlType='submit'>
                        Submit
                      </Button>
                      <div className='clearfix' />
                    </Form>
                  )}

                  <Modal
                    title=''
                    centered
                    visible={visible}
                    onOk={handleUpdate}
                    onCancel={() => {
                      setVisible(false);
                      setInputs({});
                    }}
                    okText='Update'
                    width={1000}
                  >
                    <Form id='meter-reading-form'>
                      <FormInputs
                        ncols={['col-md-6']}
                        properties={[
                          {
                            label: 'Amount Paid',
                            type: 'number',
                            step: '.01',
                            required: true,
                            bsClass: 'form-control',
                            placeholder: 'Amount Paid',
                            name: 'amount',
                            defaultValue: inputs.amount || '',
                            autoComplete: 'off',
                            onChange: handleChange,
                          },
                        ]}
                      />

                      <div className='clearfix' />
                    </Form>
                  </Modal>

                  {user && !response && (
                    <Form id='meter-form' onSubmit={handleSubmit}>
                      <FormInputs
                        ncols={['col-md-12']}
                        properties={[
                          {
                            label: 'Amount Paid',
                            type: 'number',
                            step: '.01',
                            required: true,
                            bsClass: 'form-control',
                            placeholder: 'Amount Paid',
                            name: 'amount',
                            autoComplete: 'off',
                            onChange: handleChange,
                          },
                        ]}
                      />
                      <Button type='primary' htmlType='submit'>
                        Submit
                      </Button>
                      <div className='clearfix' />
                    </Form>
                  )}

                  {response && (
                    <Button
                      onClick={() => window.location.reload()}
                      size='large'
                      block
                      type='primary'
                    >
                      Click to enter another payment
                    </Button>
                  )}
                </>
              }
            />
          </Col>

          {response && (
            <Col md={12}>
              <Card
                content={
                  <Readings
                    response={{ ...response, ...user }}
                    loading={loading}
                  />
                }
              />
            </Col>
          )}

          {user && (
            <Col md={12}>
              <Table
                columns={columns}
                dataSource={payments && payments}
                loading={!payments}
              />
            </Col>
          )}
        </Row>
      </Grid>
    </div>
  );
}
