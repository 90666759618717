import React, { useState, useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
} from 'react-bootstrap';
import Cookie from 'js-cookie';

import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import axios from 'axios';
import {message} from 'antd';

const AdminProfile = () => {
  const userInfo = ()=>JSON.parse(Cookie.get('userInfo'));
  const [inputs, setInputs] = useState(null);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    setInputs({...userInfo(), fname: userInfo().name.split(' ')[0], lname: userInfo().name.split(' ')[1]})
  }, [response]);
  const onSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.put(`/api/users/${userInfo()._id}`, inputs, {
      headers: { Authorization: ` Bearer ${userInfo().token}` },
    });
    if (data) {
      message.success('Profile updated successfully!');
      Cookie.set('userInfo', JSON.stringify(data));
      setResponse(data)
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={8}>
            <Card
              title="Edit Profile"
              content={
                <form onSubmit={onSubmit}>
        
                  <FormInputs
                    ncols={['col-md-6', 'col-md-6']}
                    properties={[
                      {
                        label: 'First name',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'First name',
                        name: 'fname',
                        value: inputs && inputs.fname,
                        onChange: handleChange
                      },
                      {
                        label: 'Last name',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Last name',
                        name: 'lname',
                        onChange: handleChange,
                        value: inputs && inputs.lname,
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={['col-md-12']}
                    properties={[
                      {
                        label: 'Phone',
                        type: 'tel',
                        bsClass: 'form-control',
                        placeholder: 'Phone Number',
                        name: 'phone',
                        value: inputs && inputs.phone,
                        onChange: handleChange
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={['col-md-4']}
                    properties={[
                      {
                        label: 'Location',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Location',
                        name: 'location',
                        value: inputs && inputs.location,
                        onChange: handleChange
                      },
                     
                    ]}
                  />
                  <Button bsStyle="info" pullRight fill type="submit">
                    Update Profile
                  </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default AdminProfile;
