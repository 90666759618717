import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import Cookie from 'js-cookie';
import { message, Popconfirm, Form, Input, Select } from 'antd';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const Messages = () => {
  const userInfo = () => JSON.parse(Cookie.get('userInfo'));

  const sendMessages = async type => {
    try {
      const { data } = await axios.post(
        '/api/bills/sendbills',
        { type },
        {
          headers: {
            Authorization: ` Bearer ${userInfo().token}`,
          },
        }
      );
      if (data) {
        return message.success('Bills sent successfully!');
      }
    } catch (error) {
      return message.error(error.message);
    }
  };

  const onFinish = async values => {
    const { recipients, body } = values;
    try {
      const { data } = await axios.post(
        '/api/bills/sendbills',
        { type: recipients, body },
        {
          headers: {
            Authorization: ` Bearer ${userInfo().token}`,
          },
        }
      );
      if (data) {
        return message.success('Message sent successfully!');
      }
    } catch (error) {
      return message.error(error.message);
    }
  };

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  return (
    <div className="content">
      <Grid fluid>
        <div className="card">
          <div className="header">
            <h4 className="title">Messages</h4>
          </div>
          <div className="content">
            <Row>
              <Col md={4}>
                <Popconfirm
                  title="Sure to send all bills?"
                  onConfirm={() => sendMessages('all')}
                >
                  <Button type="primary">Send Bills to All Users</Button>
                </Popconfirm>
              </Col>

              <Col md={4}>
                <Popconfirm
                  title="Sure to send all bills?"
                  onConfirm={() => sendMessages('read')}
                >
                  <Button type="primary">Send Bills to Read Meters</Button>
                </Popconfirm>
              </Col>

              <Col md={12} className="custom-message">
                <h4>Send Custom Message</h4>
                <Form
                  {...layout}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  layout="vertical"
                >
                  <Form.Item
                    rules={[{ required: true }]}
                    name="recipients"
                    label="To"
                  >
                    <Select style={{ width: 120 }}>
                      <Option value="all">All Contacts</Option>
                      <Option value="read">Read Meters</Option>
                      <Option value="balance">Unsettled Balances</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true }]}
                    name="body"
                    label="Message"
                  >
                    <Input.TextArea rows="8" />
                  </Form.Item>

                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Messages;
