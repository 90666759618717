/* eslint-disable react/display-name */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Cookie from "js-cookie";
import {
  message,
  Button,
  Table,
  Tag,
  Space,
  Modal,
  Upload,
  Spin,
  Popconfirm,
} from "antd";
import Card from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import {
  CheckCircleTwoTone,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import xlsxParser from "xlsx-parse-json";
import xlsx from "json-as-xlsx";
import { format } from "date-fns";

const styles = {
  display: "flex",
  alignItems: "center",
  padding: 0,
  margin: 0,
  fontSize: "24px",
};

const { info } = Modal;

export default function Meter() {
  const [inputs, setInputs] = useState({});
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [readings, setReadings] = useState(null);
  const [visible, setVisible] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [manual, setManual] = useState(false);
  const [file, setFile] = useState(null);
  const [deleted, setDeleted] = useState(null);

  const { token } = JSON.parse(Cookie.get("userInfo"));
  const history = useHistory();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    const { meterNumber } = inputs;
    if (!meterNumber) {
      message.error("Please fill all fields!");
      return;
    }
    try {
      const { data } = await axios.get(
        `/api/users/user/${inputs.meterNumber}`,
        {}
      );
      setUser(data);
    } catch (error) {
      console.log(error);
      message.error("Unable to find the meter number!");
    }
  };

  const getReadings = async () => {
    const { data } = await axios.get("/api/bills", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  };

  const handleDownload = async () => {
    const { data } = await axios.get(`/api/users/list`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const usersBills = await axios.get("/api/bills", {
      headers: { Authorization: `Bearer ${token}` },
    });

    const getBill = (id, arr) => {
      const userData = arr.filter((item) => item.user._id === id);
      return userData.length > 0
        ? userData.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )[userData.length - 1].currentReading
        : "";
    };

    let datas = [
      {
        sheet: "Bills",
        columns: [
          { label: "Name", value: "name" },
          { label: "Meter Number", value: "meterNumber" },
          { label: "Location", value: "location" },
          {
            label: "Phone",
            value: (row) => row.phone.join("/"),
          },
          { label: "Date", value: "billPeriod" },
          { label: "Previous Reading", value: "previousReading" },
          { label: "Current Reading", value: "currentReading" },
        ],
        content: await data.map((item) => ({
          ...item,
          billPeriod: new Date(new Date().setDate(20)).toLocaleDateString(),
          previousReading: getBill(item._id, usersBills.data),
        })),
      },
    ];

    let settings = {
      fileName: new Date(new Date().setDate(20)).toLocaleDateString(),
      writeOptions: {},
    };

    xlsx(datas, settings);
  };

  function showConfirm() {
    info({
      title: (
        <p>
          Reading for <b>{user.name}</b> updated!
        </p>
      ),
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      width: 600,
      content: (
        <>
          <p>
            <b>Units: </b>
            {response.units}
          </p>
          <p>
            <b>Current Reading: </b>
            {response.currentReading}
          </p>
          <p>
            <b>Amount to Pay: </b>Ksh. {response.amountToPay}
          </p>
        </>
      ),
      okText: "Close",
    });
  }

  useEffect(() => {
    if (user) {
      setLoading(true);
      getReadings().then((data) => {
        const userReadings = data.filter((item) => item.user._id === user._id);

        if (userReadings) {
          setLoading(false);
          setReadings(userReadings);
        }
      });
    }
    if (response) {
      showConfirm();
    }
  }, [user, response, updated, deleted]);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`/api/bills/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      setDeleted(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        const update = await axios.post(
          `/api/bills`,
          { ...inputs, user: user._id },
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        );

        setResponse(update.data);
        setLoading(false);
        setInputs({});
        message.success("Client reading updated!");
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response.data);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "billPeriod",
      key: "billPeriod",

      render: (date, record) => <p>{new Date(date).toLocaleDateString()}</p>,
    },
    {
      title: "Current Reading",
      dataIndex: "currentReading",
      key: "currentReading",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Amount to Pay",
      dataIndex: "amountToPay",
      key: "amountToPay",
    },
    {
      title: "Reading Status",
      key: "units",
      dataIndex: "units",
      render: (units, record) =>
        units === 0 ? (
          <Tag color="magenta">Not changed</Tag>
        ) : (
          <Tag color="cyan">Changed</Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, i) => (
        <Space size="middle" key={i}>
          {i === 0 ? (
            <>
              <a
                onClick={() => {
                  setFormValues(record);
                  setInputs(record);
                  setVisible(true);
                }}
              >
                Edit
              </a>
              <Popconfirm
                title="Are you sure to delete this reading?"
                onConfirm={() => handleDelete(record._id)}
              >
                <a>Delete</a>
              </Popconfirm>
            </>
          ) : null}
        </Space>
      ),
    },
  ];

  const handleUpdate = async () => {
    setVisible(false);
    const { data } = await axios.put(
      `/api/bills/update/${formValues._id}`,
      inputs,
      {
        headers: { Authorization: ` Bearer ${token}` },
      }
    );
    if (data) {
      setUpdated(data);
      setInputs({});
    }
  };

  const props = {
    maxCount: 1,
    customRequest: async ({ file, onSuccess, onError, onProgress }) => {
      if (file) {
        if (!file.name.includes(new Date().getFullYear().toString())) {
          return message.error(`${file.name} is not a Quontfarm bills file`);
        }
        const result = await xlsxParser.onFileSelection(file);

        if (!result.Bills) {
          setFile(null);
          return message.error(`The file is not quontfarm bills template file`);
        }

        const resultList = await result.Bills.map((item) => {
          let period = item["Date"]?.split("/");
          if (period?.length) {
            period[0] = period[0].toString().padStart(2, "0");
            period = period?.reverse().join("-");
          }
          return {
            name: item.Name,
            meterNumber: item["Meter Number"],
            billPeriod: new Date(period),
            previousReading: Number(item["Previous Reading"]),
            currentReading: Number(item["Current Reading"]),
          };
        });

        setFile(resultList);
        onSuccess("Ok");
      }
      if (!file) {
        setFile(null);
      }
    },
    accept: ".xlsx",
  };

  const handleSubmitFile = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `/api/bills/bulk`,
        { readings: file },
        {
          headers: { Authorization: ` Bearer ${token}` },
        }
      );
      if (data) {
        setLoading(false);
        message.success("Readings updated successfully!");
        setTimeout(() => history.push("/admin/table"), 2000);
      }
    } catch (error) {
      setLoading(false);
      message.error("Error uploading readings!");
    }
  };

  return (
    <div>
      <div className="meter-banner" />
      <Grid fluid>
        <Row>
          <Col md={12}>
            {!manual && (
              <Card
                title={
                  <div style={styles}>
                    <p style={{ fontSize: "24px" }}>Meter Readings</p>
                  </div>
                }
                content={
                  <Spin spinning={loading} delay={300} tip="Loading...">
                    <div className="inline">
                      <Upload {...props}>
                        <Button icon={<UploadOutlined />}>
                          Upload readings
                        </Button>
                      </Upload>
                      <Button
                        type="primary"
                        disabled={!file}
                        onClick={handleSubmitFile}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Link to="#" onClick={handleDownload}>
                        <DownloadOutlined /> Click here to download meter
                        reading template
                      </Link>
                      <span>
                        {" "}
                        or{" "}
                        <Link to="#" onClick={() => setManual(true)}>
                          Enter reading manually
                        </Link>
                      </span>
                    </div>
                  </Spin>
                }
              />
            )}
            {manual && (
              <Card
                title={
                  <div style={styles}>
                    <p style={{ fontSize: "24px" }}>
                      {user
                        ? `${user.name}'s meter reading`
                        : "Enter Meter Number"}
                    </p>{" "}
                  </div>
                }
                ctTableResponsive
                content={
                  <>
                    {!user && (
                      <Form id="meter-form" onSubmit={handleUserSubmit}>
                        <FormInputs
                          ncols={["col-md-12"]}
                          properties={[
                            {
                              label: "Meter Number",
                              className: "meterNumber-input",
                              type: "number",
                              required: true,
                              bsClass: "form-control",
                              placeholder: "Meter number",
                              name: "meterNumber",
                              onChange: handleChange,
                            },
                          ]}
                        />
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>

                        <div className="clearfix" />
                      </Form>
                    )}

                    {user && !response && (
                      <Form id="meter-reading-form" onSubmit={handleSubmit}>
                        <FormInputs
                          ncols={["col-md-6", "col-md-6"]}
                          properties={[
                            {
                              label: "Meter Reading",
                              type: "number",
                              step: ".01",
                              required: true,
                              bsClass: "form-control",
                              placeholder: "Meter reading",
                              name: "currentReading",
                              onChange: handleChange,
                            },
                            {
                              label: "Reading Date/Month",
                              type: "date",
                              required: true,
                              bsClass: "form-control",
                              placeholder: new Date(),
                              defaultValue: format(new Date(), "yyyy-MM-dd"),
                              name: "billPeriod",
                              onChange: handleChange,
                            },
                          ]}
                        />
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                        <div className="clearfix" />
                      </Form>
                    )}

                    {response && (
                      <Button
                        onClick={() => window.location.reload()}
                        size="large"
                        block
                        type="primary"
                      >
                        Click to enter another reading
                      </Button>
                    )}
                  </>
                }
              />
            )}
          </Col>
          <Modal
            title=""
            centered
            visible={visible}
            onOk={handleUpdate}
            onCancel={() => {
              setVisible(false);
              setInputs({});
            }}
            okText="Update"
            width={1000}
          >
            {inputs?.currentReading >= 0 && (
              <Form id="meter-reading-form">
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  properties={[
                    {
                      label: "Meter Reading",
                      type: "number",
                      step: ".01",
                      required: true,
                      bsClass: "form-control",
                      placeholder: "Meter reading",
                      name: "currentReading",
                      defaultValue: inputs?.currentReading,
                      value: inputs?.currentReading,
                      onChange: handleChange,
                    },
                    {
                      label: "Reading Date/Month",
                      type: "date",
                      required: true,
                      bsClass: "form-control",
                      placeholder: new Date(),
                      defaultValue:
                        inputs.billPeriod &&
                        new Date(inputs.billPeriod)
                          .toLocaleDateString()
                          .replace(/\//g, "-")
                          .split("-")
                          .reverse()
                          .join("-"),
                      name: "billPeriod",
                      value:
                        (inputs.billPeriod &&
                          new Date(inputs.billPeriod)
                            .toLocaleDateString()
                            .replace(/\//g, "-")
                            .split("-")
                            .reverse()
                            .join("-")) ||
                        "",
                      onChange: handleChange,
                    },
                  ]}
                />

                <div className="clearfix" />
              </Form>
            )}
          </Modal>

          {user && (
            <Col md={12}>
              <Table
                columns={columns}
                dataSource={readings && readings}
                loading={!readings}
              />
            </Col>
          )}
        </Row>
      </Grid>
    </div>
  );
}
