/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Table, Space, Modal, Button, message } from 'antd';
import axios from 'axios';

import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Cookie from 'js-cookie';

const UserProfile = () => {
  const [datas, setData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputs, setInputs] = useState({});
  const [response, setResponse] = useState(null);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a
          onClick={() => {
            setInputs({
              ...record,
              fname: record.name.split(' ')[0],
              lname: record.name.split(' ')[1],
            });
            setIsModalVisible(true);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              handleDelete(record._id);
            }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [response]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDelete = async (id) => {
    const userInfo = JSON.parse(Cookie.get('userInfo'));
    try {
      const { data } = await axios.delete(`/api/users/${id}`, {
        headers: { Authorization: ` Bearer ${userInfo.token}` },
      });
      setResponse(data);
      message.success('User deleted successfully!');
    } catch (error) {
      message.error('Error performing the operation!');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(Cookie.get('userInfo'));
    const form = document.getElementById('staff-form');
    const { fname, lname, phone, location } = inputs;
    if (!fname || !lname || !phone || !location) {
      message.error('Please fill all fields!');
      return;
    }
    setIsModalVisible(false);
    try {
      let data;
      if (!inputs._id) {
        data = await axios.post('/api/users/createadmin', inputs, {
          headers: {
            Authorization: ` Bearer ${userInfo.token}`,
          },
        });
      } else if (inputs._id) {
        data = await axios.put(`/api/users/${inputs._id}`, inputs, {
          headers: { Authorization: ` Bearer ${userInfo.token}` },
        });
      }

      if (data) {
        setResponse(data.data);
        message.success('Staff saved successfully!');
        form.reset();
        setInputs({});
      } else {
        message.error('Error occurred while saving data!');
      }
    } catch (error) {
      message.error('Phone number is already registered!');
    }

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    const form = document.getElementById('staff-form');
    setIsModalVisible(false);
    setInputs({});
    form.reset();
  };

  const getData = async () => {
    const { data } = await axios.get('/api/users/readers/list');
    setData(data);
  };

  return (
    <div className="content">
      <Card
        content={
          <>
            <Button type="primary" onClick={showModal}>
              New Staff
            </Button>
            <Modal
              title="Staff Details"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <form id="staff-form">
                <FormInputs
                  ncols={['col-md-6', 'col-md-6']}
                  properties={[
                    {
                      label: 'First name',
                      type: 'text',
                      bsClass: 'form-control',
                      name: 'fname',
                      required: true,
                      value: inputs.fname || '',
                      placeholder: 'First name',
                      onChange: handleChange,
                      // defaultValue: "Jane"
                    },
                    {
                      label: 'Last name',
                      type: 'text',
                      required: true,
                      bsClass: 'form-control',
                      placeholder: 'Last name',
                      name: 'lname',
                      value: inputs.lname || '',
                      onChange: handleChange,
                      // defaultValue: "Dow"
                    },
                  ]}
                />
                <FormInputs
                  ncols={['col-md-6', 'col-md-6']}
                  properties={[
                    {
                      label: 'Location',
                      type: 'text',
                      bsClass: 'form-control',
                      placeholder: 'Location',
                      required: true,
                      name: 'location',
                      value: inputs.location || '',
                      onChange: handleChange,
                      // defaultValue:
                      //   "Chui Road"
                    },
                    {
                      label: 'Phone Number',
                      type: 'number',
                      bsClass: 'form-control',
                      placeholder: 'Phone number',
                      name: 'phone',
                      value: inputs.phone || '',
                      required: true,
                      onChange: handleChange,
                      // defaultValue: "0700 123 456"
                    },
                  ]}
                />

                <div className="clearfix" />
              </form>
            </Modal>
            <Table
              columns={columns}
              dataSource={
                datas && datas.map((item, i) => ({ ...item, key: i }))
              }
              loading={!datas}
            />
          </>
        }
        loading={!datas}
      />
    </div>
  );
};

export default UserProfile;
